import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface ModalDeleteSectionProps {
    isOpen: boolean;
    toggle: () => void;
    onDelete: () => void;
}

const ModalDeleteSection: React.FC<ModalDeleteSectionProps> = ({ isOpen, toggle, onDelete }) => {
    const handleDelete = () => {
        onDelete();
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader
                className="p-3 bg-soft-primary d-flex justify-content-between align-items-center"
                toggle={toggle}
            >
                Подтверждение удаления
            </ModalHeader>
            <ModalBody>
                Вы уверены, что хотите удалить секцию? Все связанные с ней задания также будут удалены.
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleDelete}>
                    Удалить
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalDeleteSection;