import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';

interface ISection {
    id: number;
    title: string;
    videoLink?: string;
    pdfFile?: File;
    powerPointFile?: File;
}

interface ModalCreateSectionProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (title: string, videoLink?: string, pdfFile?: File, powerPointFile?: File) => void;
    sectionToEdit?: ISection;
}

const ModalCreateSection: React.FC<ModalCreateSectionProps> = ({ isOpen, toggle, onSave, sectionToEdit }) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [addVideo, setAddVideo] = useState(false);
    const [videoLink, setVideoLink] = useState('');
    const [addPdf, setAddPdf] = useState(false);
    const [pdfFile, setPdfFile] = useState<File | undefined>(undefined);
    const [addPowerPoint, setAddPowerPoint] = useState(false);
    const [powerPointFile, setPowerPointFile] = useState<File | undefined>(undefined);
    const [titleError, setTitleError] = useState<string | null>(null);
    const [videoLinkError, setVideoLinkError] = useState<string | null>(null);
    const [pdfError, setPdfError] = useState<string | null>(null);
    const [powerPointError, setPowerPointError] = useState<string | null>(null);

    useEffect(() => {
        if (sectionToEdit) {
            setSectionTitle(sectionToEdit.title);
            setAddVideo(!!sectionToEdit.videoLink);
            setVideoLink(sectionToEdit.videoLink || '');
            setAddPdf(!!sectionToEdit.pdfFile);
            setPdfFile(sectionToEdit.pdfFile || undefined);
            setAddPowerPoint(!!sectionToEdit.powerPointFile);
            setPowerPointFile(sectionToEdit.powerPointFile || undefined);
        } else {
            setSectionTitle('');
            setAddVideo(false);
            setVideoLink('');
            setAddPdf(false);
            setPdfFile(undefined);
            setAddPowerPoint(false);
            setPowerPointFile(undefined);
        }
        setTitleError(null);
        setVideoLinkError(null);
        setPdfError(null);
        setPowerPointError(null);
    }, [sectionToEdit, isOpen]);

    const validateForm = () => {
        let isValid = true;

        if (!sectionTitle.trim()) {
            setTitleError('Название секции не может быть пустым!');
            isValid = false;
        } else {
            setTitleError(null);
        }

        if (addVideo && !videoLink.trim()) {
            setVideoLinkError('Ссылка на видео не может быть пустой!');
            isValid = false;
        } else {
            setVideoLinkError(null);
        }

        if (addPdf && !pdfFile) {
            setPdfError('Необходимо загрузить PDF-файл!');
            isValid = false;
        } else {
            setPdfError(null);
        }

        if (addPowerPoint && !powerPointFile) {
            setPowerPointError('Необходимо загрузить файл презентации!');
            isValid = false;
        } else {
            setPowerPointError(null);
        }

        return isValid;
    };

    const handleSave = () => {
        if (!validateForm()) {
            return;
        }
        onSave(sectionTitle, addVideo ? videoLink : undefined, addPdf ? pdfFile : undefined, addPowerPoint ? powerPointFile : undefined);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader
                className="p-3 bg-soft-primary d-flex justify-content-between align-items-center"
                toggle={toggle}
            >
                {sectionToEdit ? 'Редактирование секции' : 'Создание секции'}
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="sectionTitle">Название секции</Label>
                        <Input
                            type="text"
                            id="sectionTitle"
                            value={sectionTitle}
                            onChange={(e) => {
                                setSectionTitle(e.target.value);
                                if (titleError && e.target.value.trim()) {
                                    setTitleError(null);
                                }
                            }}
                            placeholder="Введите название секции"
                            invalid={!!titleError}
                        />
                        {titleError && (
                            <div className="text-danger">
                                {titleError}
                            </div>
                        )}
                    </FormGroup>
                    {addVideo && (
                        <FormGroup>
                            <Label for="videoLink">Ссылка на видео</Label>
                            <Input
                                type="text"
                                id="videoLink"
                                value={videoLink}
                                onChange={(e) => {
                                    setVideoLink(e.target.value);
                                    if (videoLinkError && e.target.value.trim()) {
                                        setVideoLinkError(null);
                                    }
                                }}
                                placeholder="Введите ссылку на видео"
                                invalid={!!videoLinkError}
                            />
                            {videoLinkError && (
                                <div className="text-danger">
                                    {videoLinkError}
                                </div>
                            )}
                        </FormGroup>
                    )}
                    {addPdf && (
                        <FormGroup>
                            <Label for="pdfFile">Загрузить PDF-файл</Label>
                            <Input
                                type="file"
                                id="pdfFile"
                                accept="application/pdf"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    setPdfFile(file);
                                    if (pdfError && file) {
                                        setPdfError(null);
                                    }
                                }}
                                invalid={!!pdfError}
                            />
                            {pdfFile && (
                                <div style={{ marginTop: '0.5rem' }}>
                                    <small>Выбранный файл: {pdfFile.name}</small>
                                </div>
                            )}
                            {pdfError && (
                                <div className="text-danger">
                                    {pdfError}
                                </div>
                            )}
                        </FormGroup>
                    )}
                    {addPowerPoint && (
                        <FormGroup>
                            <Label for="powerPointFile">Загрузить файл презентации</Label>
                            <Input
                                type="file"
                                id="powerPointFile"
                                accept=".ppt,.pptx"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    setPowerPointFile(file);
                                    if (powerPointError && file) {
                                        setPowerPointError(null);
                                    }
                                }}
                                invalid={!!powerPointError}
                            />
                            {powerPointFile && (
                                <div style={{ marginTop: '0.5rem' }}>
                                    <small>Выбранный файл: {powerPointFile.name}</small>
                                </div>
                            )}
                            {powerPointError && (
                                <div className="text-danger">
                                    {powerPointError}
                                </div>
                            )}
                        </FormGroup>
                    )}
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={addVideo}
                                onChange={(e) => {
                                    setAddVideo(e.target.checked);
                                    if (!e.target.checked) {
                                        setVideoLink('');
                                        setVideoLinkError(null);
                                    }
                                }}
                            />{' '}
                            Добавить видео
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={addPdf}
                                onChange={(e) => {
                                    setAddPdf(e.target.checked);
                                    if (!e.target.checked) {
                                        setPdfFile(undefined);
                                        setPdfError(null);
                                    }
                                }}
                            />{' '}
                            Добавить PDF файл
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={addPowerPoint}
                                onChange={(e) => {
                                    setAddPowerPoint(e.target.checked);
                                    if (!e.target.checked) {
                                        setPowerPointFile(undefined);
                                        setPowerPointError(null);
                                    }
                                }}
                            />{' '}
                            Добавить файл презентации
                        </Label>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalCreateSection;