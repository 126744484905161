import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import DataTable from "react-data-table-component";

interface IAnswer {
    id: number;
    text: string;
    isCorrect: boolean;
}

interface ISection {
    id: number;
    title: string;
}

interface ITestTask {
    id: number;
    title: string;
    type: 'single' | 'multiple' | 'sorting' | 'video' | 'pdf' | 'powerpoint';
    status: 'active' | 'inactive';
    answers?: IAnswer[];
    shuffleAnswers?: boolean;
    points?: number;
    sectionId?: number;
    videoLink?: string;
    pdfFile?: File;
    powerPointFile?: File;
}

interface CreateTestTaskModalProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (newTask: ITestTask) => void;
    taskToEdit?: ITestTask;
    sections: ISection[];
}

const ModalCreateTestTask: React.FC<CreateTestTaskModalProps> = ({ isOpen, toggle, onSave, taskToEdit, sections }) => {
    const [title, setTitle] = useState('');
    const [type, setType] = useState<'single' | 'multiple' | 'sorting' | 'video' | 'pdf' | 'powerpoint'>('single');
    const [status, setStatus] = useState<'active' | 'inactive'>('active');
    const [shuffleAnswers, setShuffleAnswers] = useState(false);
    const [points, setPoints] = useState<number>(1);
    const [sectionId, setSectionId] = useState<number | undefined>(undefined);
    const [videoLink, setVideoLink] = useState<string>('');
    const [pdfFile, setPdfFile] = useState<File | undefined>(undefined);
    const [powerPointFile, setPowerPointFile] = useState<File | undefined>(undefined);
    const [answers, setAnswers] = useState<IAnswer[]>([
        { id: 1, text: '', isCorrect: false },
        { id: 2, text: '', isCorrect: false },
    ]);
    const [titleError, setTitleError] = useState<string | null>(null);
    const [videoLinkError, setVideoLinkError] = useState<string | null>(null);
    const [pdfError, setPdfError] = useState<string | null>(null);
    const [powerPointError, setPowerPointError] = useState<string | null>(null);
    const [answersError, setAnswersError] = useState<string | null>(null);
    const [pointsError, setPointsError] = useState<string | null>(null);

    useEffect(() => {
        if (taskToEdit && isOpen) {
            setTitle(taskToEdit.title);
            setType(taskToEdit.type);
            setStatus(taskToEdit.status);
            setShuffleAnswers(taskToEdit.shuffleAnswers || false);
            setPoints(taskToEdit.points || 1);
            setSectionId(taskToEdit.sectionId);
            setVideoLink(taskToEdit.videoLink || '');
            setPdfFile(taskToEdit.pdfFile || undefined);
            setPowerPointFile(taskToEdit.powerPointFile || undefined);
            setAnswers(taskToEdit.answers && taskToEdit.answers.length > 0
                ? taskToEdit.answers
                : [
                    { id: 1, text: '', isCorrect: false },
                    { id: 2, text: '', isCorrect: false },
                ]);
        } else {
            setTitle('');
            setType('single');
            setStatus('active');
            setShuffleAnswers(false);
            setPoints(1);
            setSectionId(undefined);
            setVideoLink('');
            setPdfFile(undefined);
            setPowerPointFile(undefined);
            setAnswers([
                { id: 1, text: '', isCorrect: false },
                { id: 2, text: '', isCorrect: false },
            ]);
        }

        setTitleError(null);
        setVideoLinkError(null);
        setPdfError(null);
        setPowerPointError(null);
        setAnswersError(null);
        setPointsError(null);
    }, [taskToEdit, isOpen]);

    const handleAnswerTextChange = (id: number, newText: string) => {
        setAnswers(answers.map(answer =>
            answer.id === id ? { ...answer, text: newText } : answer
        ));
    };

    const handleCorrectChange = (id: number, isCorrect: boolean) => {
        if (isCorrect && type === 'single') {
            setAnswers(answers.map(answer => ({
                ...answer,
                isCorrect: answer.id === id ? true : false,
            })));
        } else {
            setAnswers(answers.map(answer =>
                answer.id === id ? { ...answer, isCorrect } : answer
            ));
        }
    };

    const handleDeleteAnswer = (id: number) => {
        setAnswers(answers.filter(answer => answer.id !== id));
    };

    const handleAddAnswer = () => {
        const newId = answers.length > 0 ? Math.max(...answers.map(a => a.id)) + 1 : 1;
        setAnswers([...answers, { id: newId, text: '', isCorrect: false }]);
    };

    const handleTypeChange = (newType: 'single' | 'multiple' | 'sorting' | 'video' | 'pdf' | 'powerpoint') => {
        setType(newType);
        if (newType === 'single') {
            setAnswers(answers.map(answer => ({
                ...answer,
                isCorrect: false,
            })));
        }
        if (newType !== 'video') {
            setVideoLink('');
            setVideoLinkError(null);
        }
        if (newType !== 'pdf') {
            setPdfFile(undefined);
            setPdfError(null);
        }
        if (newType !== 'powerpoint') {
            setPowerPointFile(undefined);
            setPowerPointError(null);
        }
    };

    const validateForm = () => {
        let isValid = true;

        if (!title.trim()) {
            setTitleError('Текст задания не может быть пустым!');
            isValid = false;
        } else {
            setTitleError(null);
        }

        if (type === 'single' || type === 'multiple') {
            if (answers.length === 0) {
                setAnswersError('Добавьте хотя бы один вариант ответа!');
                isValid = false;
            } else if (answers.every(answer => !answer.isCorrect)) {
                setAnswersError('Выберите хотя бы один правильный ответ!');
                isValid = false;
            } else {
                setAnswersError(null);
            }
        } else {
            if (answers.length > 0 && answers.every(answer => !answer.isCorrect)) {
                setAnswersError('Выберите хотя бы один правильный ответ!');
                isValid = false;
            } else {
                setAnswersError(null);
            }
        }

        if (type === 'video' && !videoLink.trim()) {
            setVideoLinkError('Ссылка на видео не может быть пустой!');
            isValid = false;
        } else {
            setVideoLinkError(null);
        }

        if (type === 'pdf' && !pdfFile) {
            setPdfError('Необходимо загрузить PDF-файл!');
            isValid = false;
        } else {
            setPdfError(null);
        }

        if (type === 'powerpoint' && !powerPointFile) {
            setPowerPointError('Необходимо загрузить файл презентации!');
            isValid = false;
        } else {
            setPowerPointError(null);
        }

        if (points <= 0) {
            setPointsError('Балл за правильный ответ должен быть больше 0!');
            isValid = false;
        } else {
            setPointsError(null);
        }

        return isValid;
    };

    const handleSave = () => {
        if (!validateForm()) {
            return;
        }

        const newTask: ITestTask = {
            id: taskToEdit ? taskToEdit.id : Date.now(),
            title,
            type,
            status,
            answers: answers.length > 0 ? answers : undefined,
            shuffleAnswers,
            points,
            sectionId,
            videoLink: type === 'video' ? videoLink : undefined,
            pdfFile: type === 'pdf' ? pdfFile : undefined,
            powerPointFile: type === 'powerpoint' ? powerPointFile : undefined,
        };

        onSave(newTask);
        toggle();
    };

    const answerColumns = [
        {
            name: <span className="font-weight-bold justify-content-center">Верный</span>,
            cell: (row: IAnswer) => (
                <Input
                    className={"fs-16"}
                    type="checkbox"
                    checked={row.isCorrect}
                    onChange={(e) => handleCorrectChange(row.id, e.target.checked)}
                />
            ),
            width: '80px',
            center: true,
        },
        {
            name: <span className="font-weight-bold">Ответ</span>,
            cell: (row: IAnswer) => (
                <Input
                    type="textarea"
                    value={row.text}
                    onChange={(e) => handleAnswerTextChange(row.id, e.target.value)}
                    placeholder="Введите текст ответа"
                    rows={2}
                    style={{ resize: 'vertical', width: '100%' }}
                />
            ),
            grow: 1,
            style: {
                paddingTop: '10px',
                paddingBottom: '10px',
            },
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: IAnswer) => (
                <i
                    className="bi bi-trash fs-5 text-danger cursor-pointer"
                    onClick={() => handleDeleteAnswer(row.id)}
                />
            ),
            width: '80px',
        },
    ];

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            size={"lg"}
        >
            <ModalHeader
                toggle={toggle}
                className="p-3 bg-soft-primary d-flex justify-content-between align-items-center"
                id="gradingScaleModalLabel"
            >
                <div className="d-flex align-items-center">
                    <span>{taskToEdit ? 'Редактирование тестового задания' : 'Создание тестового задания'}</span>
                </div>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="taskSection">Секция</Label>
                        <Input
                            type="select"
                            id="taskSection"
                            value={sectionId || ''}
                            onChange={(e) => setSectionId(e.target.value ? Number(e.target.value) : undefined)}
                        >
                            <option value="">Без секции</option>
                            {sections.map(section => (
                                <option key={section.id} value={section.id}>
                                    {section.title}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="taskType">Тип задания</Label>
                        <Input
                            type="select"
                            id="taskType"
                            value={type}
                            onChange={(e) => handleTypeChange(e.target.value as 'single' | 'multiple' | 'sorting' | 'video' | 'pdf' | 'powerpoint')}
                        >
                            <option value="single">Единственный выбор</option>
                            <option value="multiple">Множественный выбор</option>
                            <option value="video">Видеоформат</option>
                            <option value="pdf">PDF файл</option>
                            <option value="powerpoint">PowerPoint</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="taskStatus">Статус</Label>
                        <Input
                            type="select"
                            id="taskStatus"
                            value={status}
                            onChange={(e) => setStatus(e.target.value as 'active' | 'inactive')}
                        >
                            <option value="active">Активно</option>
                            <option value="inactive">Не активно</option>
                        </Input>
                    </FormGroup>
                    {type === 'video' && (
                        <FormGroup>
                            <Label for="videoLink">Ссылка на видео</Label>
                            <Input
                                type="text"
                                id="videoLink"
                                value={videoLink}
                                onChange={(e) => {
                                    setVideoLink(e.target.value);
                                    if (videoLinkError && e.target.value.trim()) {
                                        setVideoLinkError(null);
                                    }
                                }}
                                placeholder="Введите ссылку на видео"
                                invalid={!!videoLinkError}
                            />
                            {videoLinkError && (
                                <div className="text-danger">
                                    {videoLinkError}
                                </div>
                            )}
                        </FormGroup>
                    )}
                    {type === 'pdf' && (
                        <FormGroup>
                            <Label for="pdfFile">Загрузить PDF-файл</Label>
                            <Input
                                type="file"
                                id="pdfFile"
                                accept="application/pdf"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    setPdfFile(file);
                                    if (pdfError && file) {
                                        setPdfError(null);
                                    }
                                }}
                                invalid={!!pdfError}
                            />
                            {pdfFile && (
                                <div style={{ marginTop: '0.5rem' }}>
                                    <small>Выбранный файл: {pdfFile.name}</small>
                                </div>
                            )}
                            {pdfError && (
                                <div className="text-danger">
                                    {pdfError}
                                </div>
                            )}
                        </FormGroup>
                    )}
                    {type === 'powerpoint' && (
                        <FormGroup>
                            <Label for="powerPointFile">Загрузить файл презентации</Label>
                            <Input
                                type="file"
                                id="powerPointFile"
                                accept=".ppt,.pptx"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    setPowerPointFile(file);
                                    if (powerPointError && file) {
                                        setPowerPointError(null);
                                    }
                                }}
                                invalid={!!powerPointError}
                            />
                            {powerPointFile && (
                                <div style={{ marginTop: '0.5rem' }}>
                                    <small>Выбранный файл: {powerPointFile.name}</small>
                                </div>
                            )}
                            {powerPointError && (
                                <div className="text-danger">
                                    {powerPointError}
                                </div>
                            )}
                        </FormGroup>
                    )}
                    <FormGroup>
                        <Label for="taskTitle">Текст задания</Label>
                        <Input
                            type="textarea"
                            id="taskTitle"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                if (titleError && e.target.value.trim()) {
                                    setTitleError(null);
                                }
                            }}
                            placeholder="Введите текст задания"
                            rows={3}
                            style={{ resize: 'vertical', width: '100%' }}
                            invalid={!!titleError}
                        />
                        {titleError && (
                            <div className="text-danger">
                                {titleError}
                            </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label>Варианты ответов</Label>
                        <DataTable
                            columns={answerColumns}
                            data={answers}
                            noDataComponent="Нет вариантов ответов"
                            customStyles={{
                                table: {
                                    style: {
                                        width: '100%',
                                    },
                                },
                                tableWrapper: {
                                    style: {
                                        overflowX: 'hidden',
                                    },
                                },
                            }}
                        />
                        {answersError && (
                            <div className="text-danger">
                                {answersError}
                            </div>
                        )}
                    </FormGroup>
                    <div className="d-flex align-items-center">
                        <FormGroup className="mt-2 col-md-6">
                            <Label for="taskPoints">Балл за правильный ответ</Label>
                            <Input
                                type="number"
                                id="taskPoints"
                                value={points}
                                onChange={(e) => {
                                    setPoints(Number(e.target.value));
                                    if (pointsError && Number(e.target.value) > 0) {
                                        setPointsError(null);
                                    }
                                }}
                                min="1"
                                step="1"
                                placeholder="Введите балл"
                                invalid={!!pointsError}
                            />
                            {pointsError && (
                                <div className="text-danger">
                                    {pointsError}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup check className="ms-3">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    className="fs-16"
                                    checked={shuffleAnswers}
                                    onChange={(e) => setShuffleAnswers(e.target.checked)}
                                />{' '}
                                Перемешивать ответы
                            </Label>
                        </FormGroup>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button color="primary" onClick={handleAddAnswer} className="me-3">
                    Добавить вариант ответа
                </Button>
                <Button color="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalCreateTestTask;