import React, {useEffect, useState} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {
    setDateSign,
    setGroupModalSignDO, setModalAdd,
    setModalSignDO
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Russian} from 'flatpickr/dist/l10n/ru'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ErrorMessage, Field, Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import { getAllInfoGroupDo } from "../../../store/reducers/widgets/ActionCreator";

const ModalAddSignTableAllInfoGroup = () => {

    const dispatch = useAppDispatch();
    const {modalSignDO, dateSign, groupModalSignDO, groupsDO, date} = useAppSelector(state => state.WidgetReducer);
    const [error, setError] = useState<string>('');
    const [codeValid, setCodeValid] = useState<boolean>(true);
    useEffect(() => {
        modalSignDO &&
        AxiosClient.post('/send_code_employee_sign');
    }, [modalSignDO]);

    useEscapeKey(() => dispatch(setModalAdd(false)), modalSignDO, "modalSignDO");

    return (
        <Modal id="modalSignDO" isOpen={modalSignDO} toggle={() => {
            dispatch(setModalSignDO(!modalSignDO))
            setError('')
        }}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => {
                dispatch(setModalSignDO(!modalSignDO))
                setError('')
            }} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Подписать
            </ModalHeader>
            <ModalBody>
                <Formik
                 initialValues={{
                     code: ''
                 }}
                 onSubmit={(values) => {
                     AxiosClient.post('/check_code_sign_employee', {code: values.code})
                         .then(() => AxiosClient.post('/set_sign_group_do', {date: dateSign, group_id: groupModalSignDO})
                             .then(() => {
                                 dispatch(getAllInfoGroupDo(date)).then(() => {
                                     dispatch(setModalSignDO(false));
                                     setError('');
                                 });
                             })
                             .catch((reason) => {
                                 setError(reason.response.data)
                             })
                         )
                         .catch(() => setCodeValid(false))
                 }}>
                    <Form>
                        <Alert>Код отправлен в Telegram</Alert>
                        {
                            error &&
                            <Alert color="danger" className="mt-2">{error}</Alert>
                        }
                        <div className="col-auto mb-2">
                            <label>Период:</label>
                            <div className="col">
                                <div className="input-group">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={dateSign}
                                        options={{
                                            locale: Russian, mode: "range", dateFormat: "d m Y", disableMobile: true
                                        }}
                                        onChange={(date) => dispatch(setDateSign(date))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 shadow">
                            <label>Группа:</label>
                            <select className="form-select" value={groupModalSignDO}
                                    onChange={(e) => dispatch(setGroupModalSignDO(e.target.value))}>
                                {groupsDO.map((group) => {
                                    return <option key={group.group_id}
                                                   value={group.group_id}>{group.group_name}</option>
                                })}
                            </select>
                        </div>
                        <div className="mb-3 shadow">
                            <label>Код:</label>
                            <Field name="code" className="form-control shadow"/>
                            <ErrorMessage name="code">{(msg) => <Alert color="danger" className="mt-2">{msg}</Alert>}</ErrorMessage>
                            {
                                !codeValid &&
                                <Alert color="danger" className="mt-2">Не правильно введен код</Alert>
                            }
                        </div>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit" className="btn btn-primary" id="addNewProject">Сохранить</button>
                        </div>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddSignTableAllInfoGroup;
