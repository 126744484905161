import React from 'react';
import { Card, CardBody, CardHeader } from "reactstrap";
import imgfront from '../../../assets/images/planingEducation/pand.jpg';
import imgtest from '../../../assets/images/planingEducation/test.png';
import imgsql from '../../../assets/images/planingEducation/sql.jpg';
import imghr_super from '../../../assets/images/planingEducation/hr_super.jpg';
import imghr_2 from '../../../assets/images/planingEducation/hr_2.jpg';
import { useNavigate } from 'react-router-dom';

interface ITest {
    id: number;
    image: string;
    title: string;
    description: string;
    date: Date;
}

interface IRecentTestsProps {
    selectedDate: Date | undefined;
    onEditTest: (testData: ITest) => void;
}

const RecentTests: React.FC<IRecentTestsProps> = ({ selectedDate, onEditTest }) => {
    const navigate = useNavigate();

    const tests: ITest[] = [
        {
            id: 1,
            image: imghr_2,
            title: "Профессия HR и всё о ней",
            description: "Профессия HR",
            date: new Date(2025, 3, 15),
        },
        {
            id: 2,
            image: imghr_super,
            title: "База для HR-менеджеров",
            description: "Профессия HR",
            date: new Date(2025, 3, 10),
        },
        {
            id: 3,
            image: imgsql,
            title: "Структуры данных и SQL",
            description: "Профессия разработчик",
            date: new Date(2025, 3, 5),
        },
        {
            id: 4,
            image: imgfront,
            title: "Профессия Front-разработчик",
            description: "Профессия разработчик",
            date: new Date(2025, 3, 20),
        },
        {
            id: 5,
            image: imgtest,
            title: "Техника продаж",
            description: "Профессия продажник",
            date: new Date(2025, 3, 15),
        },
        {
            id: 6,
            image: imgtest,
            title: "Техника продаж",
            description: "Профессия продажник",
            date: new Date(2025, 2, 15),
        },
    ];

    const filteredTests = selectedDate
        ? tests.filter(test => {
            const testDate = new Date(test.date);
            return (
                testDate.getFullYear() === selectedDate.getFullYear() &&
                testDate.getMonth() === selectedDate.getMonth()
            );
        })
        : tests;

    return (
        <Card>
            <CardHeader className="align-items-center d-flex justify-content-between">
                <h4 className="card-title mb-0 flex-grow-1">Тесты текущего периода</h4>
            </CardHeader>
            <CardBody>
                {filteredTests.length > 0 ? (
                    filteredTests.map(test => (
                        <div key={test.id} className="d-flex align-items-center mb-3">
                            <img
                                src={test.image}
                                alt={test.title}
                                className="rounded-circle me-3"
                                style={{ width: '50px', height: '50px' }}
                            />
                            <div className="flex-grow-1">
                                <h5 className="mb-1 cursor-pointer"
                                    onClick={() => navigate(`/education/create_test_tasks/${test.id}`, { state: { testTitle: test.title } })}>{test.title}</h5>
                                <p className="text-muted mb-0">{test.description}</p>
                            </div>
                            <div className="d-flex gap-2">
                                {/*<button*/}
                                {/*    className="btn"*/}
                                {/*    title="Добавить задания"*/}
                                {/*    onClick={() => navigate(`/education/create_test_tasks/${test.id}`, { state: { testTitle: test.title } })}*/}
                                {/*>*/}
                                {/*    <i className="bi bi-plus-lg fw-bold fs-5 text-black"/>*/}
                                {/*</button>*/}
                                {/*<button*/}
                                {/*    className="btn"*/}
                                {/*    title="Редактировать"*/}
                                {/*    onClick={() => onEditTest(test)}*/}
                                {/*>*/}
                                {/*    <i className="bi bi-pencil fs-5 text-black"/>*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-muted">Нет тестов за выбранный период.</p>
                )}
            </CardBody>
        </Card>
    );
};

export default RecentTests;