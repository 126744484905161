import React, { useState, useEffect } from 'react';
import { Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Input, Label } from "reactstrap";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import ModalCreateTest from "../../components/education/PlaningEducation/modal/ModalCreateTest";
import NotificationShow from "../../components/widgets/NotificationShow";

import imgfront from '../../assets/images/planingEducation/pand.jpg';
import imgtest from '../../assets/images/planingEducation/test.png';
import imgsql from '../../assets/images/planingEducation/sql.jpg';
import imghr_super from '../../assets/images/planingEducation/hr_super.jpg';
import imghr_2 from '../../assets/images/planingEducation/hr_2.jpg';

interface ITest {
    id: number;
    image: string;
    title: string;
    description: string;
    date: Date;
    activeTasksCount: number;
}

const TestsPage: React.FC = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editTestData, setEditTestData] = useState<ITest | null>(null);
    const [search, setSearch] = useState('');
    const [filteredTests, setFilteredTests] = useState<ITest[]>([]);

    const initialTests: ITest[] = [
        {
            id: 1,
            image: imghr_2,
            title: "Профессия HR и всё о ней",
            description: "Профессия HR",
            date: new Date(2025, 3, 15),
            activeTasksCount: 2,
        },
        {
            id: 2,
            image: imghr_super,
            title: "База для HR-менеджеров",
            description: "Профессия HR",
            date: new Date(2025, 3, 10),
            activeTasksCount: 5,
        },
        {
            id: 3,
            image: imgsql,
            title: "Структуры данных и SQL",
            description: "Профессия разработчик",
            date: new Date(2025, 3, 5),
            activeTasksCount: 2,
        },
        {
            id: 4,
            image: imgfront,
            title: "Профессия Front-разработчик",
            description: "Профессия разработчик",
            date: new Date(2025, 3, 20),
            activeTasksCount: 4,
        },
        {
            id: 5,
            image: imgtest,
            title: "Техника продаж",
            description: "Профессия продажник",
            date: new Date(2025, 3, 15),
            activeTasksCount: 1,
        },
        {
            id: 6,
            image: imgtest,
            title: "Техника продаж",
            description: "Профессия продажник",
            date: new Date(2025, 2, 15),
            activeTasksCount: 0,
        },
    ];

    const [tests, setTests] = useState<ITest[]>(initialTests);

    useEffect(() => {
        let filtered = [...tests];

        if (search) {
            filtered = filtered.filter(test =>
                test.title.toLowerCase().includes(search.toLowerCase())
            );
        }

        setFilteredTests(filtered);
    }, [search, tests]);

    const columns = [
        {
            name: <span className="font-weight-bold accessibility_size_table">Название</span>,
            selector: (row: ITest) => row.title,
            cell: (row: ITest) => <span className="accessibility_size_table">{row.title}</span>,
            sortable: true,
            wrap: true,
        },
        {
            name: <span className="font-weight-bold accessibility_size_table">Описание</span>,
            selector: (row: ITest) => row.description,
            cell: (row: ITest) => <span className="text-muted accessibility_size_table">{row.description}</span>,
            sortable: true,
            wrap: true,
        },
        {
            name: <span className="font-weight-bold accessibility_size_table">Активные задания</span>,
            selector: (row: ITest) => row.activeTasksCount,
            cell: (row: ITest) => <span className="text-muted accessibility_size_table">{row.activeTasksCount}</span>,
            sortable: true,
            width: '250px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: ITest) => (
                <i
                    className="bi bi-plus-lg fs-5 text-black cursor-pointer"
                    onClick={() => navigate(`/education/create_test_tasks/${row.id}`, { state: { testTitle: row.title } })}
                    title="Добавить задания"
                />
            ),
            width: '80px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: ITest) => (
                <i
                    className="bi bi-pencil fs-5 text-black cursor-pointer"
                    onClick={() => handleEditTest(row)}
                    title="Редактировать"
                />
            ),
            width: '80px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: ITest) => (
                <i
                    className="bi bi-trash fs-5 text-danger cursor-pointer"
                    onClick={() => handleDeleteTest(row.id)}
                    title="Удалить"
                />
            ),
            width: '80px',
        },

    ];

    const handleEditTest = (test: ITest) => {
        setEditTestData(test);
        setIsModalOpen(true);
    };

    const handleDeleteTest = (testId: number) => {
        setTests(tests.filter(test => test.id !== testId));
    };

    const handleAddTest = () => {
        setEditTestData(null);
        setIsModalOpen(true);
    };

    const handleSaveTest = (data: { title: string; description: string; gradingScale: string; timeLimit: boolean; timeLimitMinutes: number; attemptLimit: boolean; attemptLimitCount: number; taskOrder: string; allowSkip: boolean }) => {
        if (editTestData) {
            setTests(tests.map(test =>
                test.id === editTestData.id
                    ? { ...test, title: data.title, description: data.description }
                    : test
            ));
        } else {
            const newTest: ITest = {
                id: Date.now(),
                image: imgtest,
                title: data.title,
                description: data.description,
                date: new Date(),
                activeTasksCount: 0,
            };
            setTests([...tests, newTest]);
        }
        setEditTestData(null);
        setIsModalOpen(false);
    };

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Все тесты</title>
            </Helmet>
            <Container fluid>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">Все тесты</h4>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col md={4}>
                                <Label>Поиск по названию:</Label>
                                <Input
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Введите название теста"
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                        <div>
                            <DataTable
                                columns={columns}
                                data={filteredTests}
                                noDataComponent="Нет тестов для отображения"
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Тестов на странице:',
                                    rangeSeparatorText: 'из',
                                }}
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end align-items-center text-muted">
                        <button className="btn btn-primary" onClick={handleAddTest}>
                            Добавить тест
                        </button>
                    </CardFooter>
                </Card>
            </Container>
            <ModalCreateTest
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(false)}
                onSave={handleSaveTest}
                initialData={editTestData}
            />
            <NotificationShow />
        </div>
    );
};

export default TestsPage;