import React, { FC } from 'react';
import { useAppDispatch } from "../../../../hooks/redux";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    FormGroup,
    Label,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { setShowNotification } from "../../../../store/reducers/widgets/WidgetsSlice";

interface IModalCreateEducationProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (data: {
        title: string;
        description: string;
    }) => void;
    initialData?: {
        id?: number;
        title: string;
        description: string;
    } | null;
}

const ModalCreateEducation: FC<IModalCreateEducationProps> = ({ isOpen, toggle, onSave, initialData }) => {
    const dispatch = useAppDispatch();

    return (
        <Modal
            id="ModalCreateEducation"
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary" id="createEducationModalLabel">
                {initialData ? "Редактировать обучение" : "Создать обучение"}
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        title: initialData?.title || '',
                        description: initialData?.description || '',
                    }}
                    validationSchema={Yup.object({
                        title: Yup.string().required('Название обучения обязательно'),
                        description: Yup.string().required('Описание обучения обязательно'),
                    })}
                    onSubmit={(values) => {
                        onSave({
                            title: values.title,
                            description: values.description,
                        });
                        dispatch(setShowNotification({ type: "success", message: initialData ? 'Обучение успешно обновлено!' : 'Обучение успешно создано!', show: true }));
                        toggle();
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <FormGroup className="mb-3">
                                <Label for="title">Название обучения</Label>
                                <Field
                                    name="title"
                                    type="text"
                                    className={`form-control ${errors.title && touched.title ? 'is-invalid' : ''}`}
                                    placeholder="Введите название обучения"
                                />
                                {errors.title && touched.title && (
                                    <div className="invalid-feedback">{errors.title}</div>
                                )}
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <Label for="description">Описание обучения</Label>
                                <Field
                                    name="description"
                                    as="textarea"
                                    className={`form-control ${errors.description && touched.description ? 'is-invalid' : ''}`}
                                    placeholder="Введите описание обучения"
                                    rows="3"
                                />
                                {errors.description && touched.description && (
                                    <div className="invalid-feedback">{errors.description}</div>
                                )}
                            </FormGroup>

                            <div className="hstack gap-2 justify-content-end mt-1">
                                <Button color="primary" type="submit">
                                    {initialData ? "Сохранить" : "Создать"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalCreateEducation;